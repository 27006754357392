import React from 'react'
import { graphql } from 'gatsby';
import Layout from "../../../components/Layout";
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from '../../../components/News'
import CaseStudeiesTopSection from '../../../components/CaseStudeiesTopSection';
import CaseStudyDescription from '../../../components/caseStudyDescription';
import CaseStudeiesSimplified from '../../../components/CaseStudeiesSimplified'
import headerImage from '../../../images/caseStudies/banumukhtaroffice.png'

const BanuMukhtar = ({ data }) => {
  return (
    <div className='CaseStudeies'>
      <Layout pageTitle={"Visiomate - CRM Solution for Banu Mukhtar"}
      descrip = {"Strengthen customer relationships and drive business growth with our powerful CRM solution. Improve sales, marketing, and customer service. Learn more!"}>
        <CaseStudeiesTopSection
          path='projects'
          headerData={{
            title: "Customized ERP Solution: tairlor made CRM and Sales workflows",
            peragraph: "The exceptional Odoo-based solution, specially designed to meet Banu Mukhtar's company requirements. Enhanced business processes, optimized productivity, and encountered unmatched ease of use customized just for them."
            , image: headerImage
          }}
          data={data} />
        <CaseStudyDescription
          caseStudyData={{
            ITitle: "Introduction",
            Idescription: "Having been established in 1964, Banu Mukhtar is one of Pakistan's leading engineering and construction companies. It is one of the oldest construction enterprises in the nation, with a long history spanning over 55 years. Well-known for its knowledge, Banu Mukhtar has shaped Pakistan's urban landscape by considerably aiding in the construction of numerous landmarks, such as skyscrapers and corporate offices.",
            image: headerImage,
            data: [

              {
                Title: "keyReqProblem",
                Point1: "Centralized ERP solution",
                Point2: "Tailor made CRM and Sales process",
                Point3: ""
              },
              {
                Title: "Solution",
                Sdescription: "Visiomate provided an ERP solution based on Odoo Community Edition and customized the solution to mold it as per their needs. We focussed on CRM and Sales the most to enable there sales team to increase there coordination and eficiency.",
                Point1: "Centralised Solution for whole sales team",
                Point2: "Enhanced teams communication through Integration",
                Point3: "Easier documentation and tracking",
              },
            ],
          }}
          data={data} />
        <CaseStudeiesSimplified
          TextData={{
            title: 'Approach that Achieved Success'
            ,
            Data: [
              {
                peragraph: 'Define Requirements',
                list: [
                  'Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.',
                  'List down the essential features and functionalities required.'
                ]
              },
              {
                peragraph: 'Demo and Evaluation',
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process."
                ]
              },
              {
                peragraph: 'Scalability and Integration',
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations."
                ]
              },
              {
                peragraph: 'UAT & User Feedback',
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch."
                ]
              },
            ]
          }} />
        <News isAbout={true} career={true} />
      </Layout>
    </div >
  )
}

export default BanuMukhtar
export const query = graphql`
  query banuImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(
      relativePath: { eq: "caseStudies/banumukhtaroffice.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(
      relativePath: { eq: "caseStudies/banumukhtaroffice.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
